import i18n from 'i18next';
import deTranslation from './de.json';
import enTranslation from './en.json';
import deLanguages from './de.languages.json';
import enLanguages from './en.languages.json';
import deDateRangePicker from './de.daterangepicker.json';
import enDateRangePicker from './en.daterangepicker.json';
import deMetrics from './de.metrics.json';
import enMetrics from './en.metrics.json';
import deTour from './de.tour.json';
import enTour from './en.tour.json';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

// Initialize i18n with translations
i18n
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		detection: {},
		interpolation: { escapeValue: false },
		// Set default language
		fallbackLng: 'en',
		supportedLngs: ['de', 'en'],
		resources: {
			de: {
				translation: deTranslation,
				languages: deLanguages,
				daterangepicker: deDateRangePicker,
				metrics: deMetrics,
				tour: deTour,
			},
			en: {
				translation: enTranslation,
				languages: enLanguages,
				daterangepicker: enDateRangePicker,
				metrics: enMetrics,
				tour: enTour,
			},
		},
	});
