import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { PrimeReactProvider } from 'primereact/api';
import 'primereact/resources/themes/lara-light-blue/theme.css';
import i18n from 'i18next';
import { lazily } from 'react-lazily';
import { I18nextProvider } from 'react-i18next';

import './translations/i18n';

import * as Sentry from '@sentry/react';

Sentry.init({
	dsn: 'https://6a6f2106d657eee0174407db4988c80c@o4507777577582592.ingest.de.sentry.io/4507777580531792',
	integrations: [
		Sentry.browserTracingIntegration(),
		Sentry.browserProfilingIntegration(),
		Sentry.replayIntegration(),
		/*Sentry.feedbackIntegration({
			// Additional SDK configuration goes in here, for example:
			colorScheme: 'system',
			
		}),*/
	],
	// Tracing
	tracesSampleRate: 1.0, //  Capture 100% of the transactions
	// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
	tracePropagationTargets: ['localhost', /^https:\/\/app\.lead-metrics\.com/],
	// Session Replay
	replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const { default: App } = lazily(() => import('./App'));
const { Auth } = lazily(() => import('./lib/Auth'));

ReactDOM.createRoot(document.getElementById('root')!).render(
	<React.StrictMode>
		<PrimeReactProvider value={{ ripple: true }}>
			<I18nextProvider i18n={i18n}>
				<Auth>
					<Suspense>
						<App />
					</Suspense>
				</Auth>
			</I18nextProvider>
		</PrimeReactProvider>
	</React.StrictMode>,
);
